import Head from 'next/head'
import { useAmp } from 'next/amp'
import { useEffect } from 'react'

const withPageCss = (Component, { name, DevCSS }) => {
  const PageCSSProvider = (props) => {
    const isAmp = useAmp()
    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        DevCSS()
      }
    }, [])
    const optialCssMap = process.env.NODE_ENV === 'production' ? require('../.freec/optimal-css.json') : {}
    return (
      <>
        {process.env.NODE_ENV === 'production' && !isAmp && (
          <Head>
            <link href={optialCssMap[name]} rel="preload" as="style" />
            <link href={optialCssMap[name]} rel="stylesheet" key={`${name}-optimal-style`} />
          </Head>
        )}

        <Component {...props} />
      </>
    )
  }
  PageCSSProvider.getInitialProps = Component.getInitialProps
  PageCSSProvider.getStaticProps = Component.getStaticProps
  return PageCSSProvider
}
export default withPageCss
