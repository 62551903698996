export const ENUM_SEARCH_KEY = [
  {
    name: 'Marketing',
    link: 'jobs/marketing'
  },
  {
    name: 'sales',
    link: 'jobs/sales'
  },
  {
    name: 'Javascript',
    link: 'jobs/javascript'
  },
  {
    name: 'Java',
    link: 'jobs/java'
  },
  {
    name: 'Android',
    link: 'jobs/android'
  },
  {
    name: 'IOS',
    link: 'jobs/ios'
  },
  {
    name: 'Python',
    link: 'jobs/python'
  },
  {
    name: 'Mobile',
    link: 'jobs/mobile'
  }
]
