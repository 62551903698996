import { FastJsonList } from '../../types/common'
import { PublicTopCompany } from '../../types/server/company/PublicTopCompany'

export const mapTopCompany = (companyResponse: FastJsonList<PublicTopCompany>) => ({
  data: companyResponse.data.map((item) => ({
    availabaleJobNumber: item.attributes.jobs_count,
    companySlug: item.attributes.slug,
    companyId: item.attributes.id,
    companyName: item.attributes.name,
    locationId: item.attributes.locations.data?.attributes.id,
    locationName: item.attributes.locations.data?.attributes.state,
    locationNameVn: item.attributes.locations.data?.attributes.state_vn,
    logoUrl: item.attributes.logo_url,
    custom_career_url: item.attributes?.custom_career_url
  }))
})
