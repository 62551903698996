import { X_AUTH_TOKEN } from '../../utillities/CONSTANT_COOKIES'
import { COOKIES_NAME_CANDIDATE_ONBOADING, COOKIES_NAME_LANGUAGE, deleteCookie, setCookie } from '../../utillities/Cookies'
import { refreshUserData } from '../useCurrentUser'

export const useStoreTokenAndFetchUser = (): ((token: string) => Promise<any>) => {
  return (token) => {
    setCookie(X_AUTH_TOKEN, token)
    return refreshUserData().then((profile) => {
      if (!profile.is_on_boarding_success && !profile.skip_onboarding) {
        setCookie(COOKIES_NAME_CANDIDATE_ONBOADING, 'true')
      } else {
        deleteCookie(COOKIES_NAME_CANDIDATE_ONBOADING)
      }
      deleteCookie('_vercel_no_cache')
      setCookie(
        COOKIES_NAME_LANGUAGE,
        {
          0: 'en',
          1: 'vi'
        }[profile.language]
      )
      return profile
    })
  }
}
