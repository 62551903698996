import { useEffect } from 'react'
import { COOKIES_NAME_CANDIDATE_ONBOADING, getCookie } from '../utillities/Cookies'
import { LINK_URL_ONBOARDING_CANDIDATE } from '../utillities/URL'
import useAppRouter from './useAppRouter'

const useCheckOnboarding = () => {
  const router = useAppRouter()
  useEffect(() => {
    if (getCookie(COOKIES_NAME_CANDIDATE_ONBOADING) === 'true') {
      router.push(LINK_URL_ONBOARDING_CANDIDATE)
    }
  }, [])
}
export default useCheckOnboarding
