import React, { FC, useState, useEffect } from 'react'

const ListSkill: FC<{ skillsList? }> = ({ skillsList }) => {
  const minHeightOfSkillItem = 44
  const [skills, setSkills] = useState([])
  const [numberHiddenItems, setNumberHiddenItems] = useState(0)
  const [heightOfListSkillWrapper, setHeightOfListSkillWrapper] = useState(0)
  const [heightOfSkillItem, setHeightOfSkillItem] = useState(minHeightOfSkillItem)

  const skillListWrapperRef = React.useRef<HTMLDivElement>(null)
  const skillItemRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (skillsList.length > 0) {
      const skillsTmp = []
      skillsList.map((skillItem) => {
        skillsTmp.push({
          id: skillItem.attributes.id,
          name: skillItem.attributes.name
        })
      })

      setSkills(skillsTmp)
    }
  }, [skillsList])

  const updateHeightNumber = () => {
    new Promise(() => {
      setHeightOfListSkillWrapper(skillListWrapperRef.current?.offsetHeight)
    }).then(() => {
      setHeightOfSkillItem(skillItemRef.current?.offsetHeight)
    })
  }

  useEffect(() => {
    updateHeightNumber()
  }, [skills])

  useEffect(() => {
    const heightOfListSkillWrapperTmp = heightOfListSkillWrapper - Math.floor(heightOfListSkillWrapper / heightOfSkillItem) * 12
    if (skills.length > 1 && heightOfListSkillWrapper && heightOfListSkillWrapper && heightOfListSkillWrapperTmp > heightOfSkillItem) {
      setSkills(skills.slice(0, skills.length - 1))
      setNumberHiddenItems(numberHiddenItems + 1)
    }
  }, [heightOfListSkillWrapper, skills])

  return (
    <>
      <div ref={skillListWrapperRef}>
        {skills.length > 0 &&
          skills.map((skill) => (
            <span className="hot-job__require-skill" {...{ ref: skill.id === 0 ? skillItemRef : null }} key={skill.id}>
              {skill.name}
            </span>
          ))}
        {numberHiddenItems > 0 && (
          <span className={`hot-job__require-skill ${heightOfListSkillWrapper === heightOfSkillItem * 2 ? 'mt-8-px' : ''}`}>
            +{numberHiddenItems}
          </span>
        )}
      </div>
    </>
  )
}

export default ListSkill
