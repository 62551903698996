import IMAGE_1 from '../assets/img/news_img_1.webp'
import IMAGE_2 from '../assets/img/news_img_2.webp'
import IMAGE_3 from '../assets/img/news_img_3.webp'
import IMAGE_4 from '../assets/img/news_img_4.webp'
import IMAGE_5 from '../assets/img/news_img_5.webp'
import IMAGE_6 from '../assets/img/news_img_6.webp'

import LOGO_1 from '../assets/img/news_logo_1.webp'
import LOGO_2 from '../assets/img/news_logo_2.webp'
import LOGO_3 from '../assets/img/news_logo_3.webp'
import LOGO_4 from '../assets/img/news_logo_4.webp'
import LOGO_6 from '../assets/img/news_logo_6.webp'

export const listNewFixture = [
  {
    id: 1,
    url: 'https://www.brandsvietnam.com/20316-CEO-freeC-Co-hoi-nao-cho-cac-nganh-tuyen-dung-hau-COVID19',
    image: IMAGE_1,
    logo: LOGO_1,
    title: 'CEO freeC: Cơ hội nào cho các ngành tuyển dụng hậu COVID?',
    content:
      'CEO Kazuki Kunimoto nhận định nhu cầu tuyển dụng nhiều ngành nghề sẽ đảo chiều hậu COVID-19: ngành đang điêu đứng sẽ hồi phục mạnh mẽ trong khi ngành đang “ăn nên làm ra” sẽ cắt giảm nhân sự.'
  },
  {
    id: 2,
    url:
      'https://dantri.com.vn/lao-dong-viec-lam/diem-danh-5-website-tuyen-dung-viec-lam-uy-tin-hieu-qua-hang-dau-nam-2020-20200207110911233.htm',
    image: IMAGE_2,
    logo: LOGO_2,
    title: 'Điểm danh 5 website tuyển dụng việc làm uy tín hiệu quả hàng đầu năm 2020',
    content:
      'Vietnamwork sở hữu mạng lưới đối tác lớn, HRChannel chuyên tuyển dụng nhân sự cấp cao, TopCV có lợi thế về nhân lực trẻ, còn freeC khác biệt với các tính năng công nghệ mới mẻ.'
  },
  {
    id: 3,
    url: 'https://startup.vnexpress.net/tin-tuc/hanh-trinh-khoi-nghiep/tham-vong-cua-freec-khi-hop-tac-persol-asia-pacific-4103743.html',
    image: IMAGE_3,
    logo: LOGO_3,
    title: 'Tham vọng của freeC khi hợp tác PERSOL Asia Pacific',
    content: 'Khoản đầu tư 900.000 USD sẽ được freeC tăng cường sức mạnh nền tảng (platform), trí tuệ nhân tạo và chuyên gia headhunter.'
  },
  {
    id: 4,
    url: 'https://baodautu.vn/freec-ra-mat-chuyen-trang-moi-danh-rieng-cho-nha-tuyen-dung-d124280.html',
    image: IMAGE_4,
    logo: LOGO_4,
    title: 'FreeC ra mắt chuyên trang mới dành riêng cho nhà tuyển dụng',
    content:
      'Ngoài gói đăng tin tuyển dụng và quảng bá thương hiệu miễn phí cho doanh nghiệp, freeC còn mang đến dịch vụ “săn đầu người” với chi phí tiết kiệm đến 30% cho các vị trí cấp cao.'
  },
  {
    id: 5,
    url: 'https://www.brandsvietnam.com/20398-Startup-cong-nghe-tuyen-dung-freeC-goi-von-thanh-cong-900000USD-tu-Persol',
    image: IMAGE_5,
    logo: LOGO_1,
    title: 'Startup công nghệ tuyển dụng freeC gọi vốn thành công 900.000USD từ Persol',
    content:
      'Cú bắt tay chiến lược kỳ vọng giúp freeC rút ngắn thời gian tuyển dụng bằng AI và tăng độ chính xác khi gợi ý ứng viên lên gấp 3 lần, cũng như mở rộng thị trường hoạt động sang 13 quốc gia trong khu vực.'
  },
  {
    id: 6,
    url: 'https://theleader.vn/nang-cap-giao-dien-moi-freec-nhanh-chong-chiem-cam-tinh-hang-nghin-nha-tuyen-dung-1609925865318.htm',
    image: IMAGE_6,
    logo: LOGO_6,
    title: `Nâng cấp giao diện mới – FreeC nhanh chóng 'chiếm cảm tình' hàng nghìn nhà tuyển dụng`,
    content:
      'Giao diện freeC không chỉ cải tiến thiết kế dễ sử dụng hơn, mà còn cho phép nhà tuyển dụng đăng tin miễn phí không giới hạn, chia sẻ quyền tuyển dụng cho cả đội nhóm công ty.'
  }
]
