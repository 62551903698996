import { FastJsonList } from '../../types/common'
import { BannerType } from '../../types/view/banner/banner'
import { API_URL_GET_BANNER_BY_PAGE, API_URL_TRACKING_BANNER } from '../../utillities/APIConstant'
import Client from '../../utillities/Client'

export const apiFetchBanner = (pageId: number, size = 1) => {
  return Client.get<FastJsonList<BannerType>>(API_URL_GET_BANNER_BY_PAGE(pageId, size), {})
}
export const apiCountViewBanner: (bannerId: number) => Promise<any> = (bannerId) => {
  return Client.post<any>(API_URL_TRACKING_BANNER(bannerId), {})
}
