import { useEffect } from 'react'
import useCurrentUser from './useCurrentUser'
import { useTranslation } from './useTranslation'

const useRedirectUserLang = () => {
  const [currentUser] = useCurrentUser()
  const { currentLanguageNumber, changeLanguage, fromNumberToCode } = useTranslation()
  useEffect(() => {
    if (currentUser && currentLanguageNumber !== currentUser.language) {
      changeLanguage(fromNumberToCode(currentUser.language))
    }
  }, [currentUser])
}
export default useRedirectUserLang
