export const createSingleTimeInvoke = <F extends Function>(func: F) => {
  let invoked = false
  return function (...args) {
    if (invoked === false) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      invoked = true
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return func(...args)
    }
  }
}
