import { FC } from 'react'
import Row from '../../../components/base/Grid/Row'
import Col from '../../../components/base/Grid/Col'
import { useTranslation } from '../../../hooks/useTranslation'
import { Trans } from 'react-i18next'
import Button from '../../../components/base/buttons/Button'
import { LP_CANDIDATE_BUTTON_CREATE_CV } from '../../../utillities/ConstantAutomation'
import { LINK_URL_LP_CV_ONLINE, LINK_URL_CANDIDATES_CV } from '../../../utillities/LinkURL'
import useAppRouter from '../../../hooks/useAppRouter'
import Image from 'next/image'

// import BACKGROUND from '../assets/img/feature_bg.webp'
import IMAGE_GRID from '../assets/img/feature-img.webp'
import useCurrentUser from '../../../hooks/useCurrentUser'

const FeatureLPCandidate: FC = () => {
  const { translate } = useTranslation()
  const router = useAppRouter()

  const [currentUser] = useCurrentUser()

  return (
    <div className="feature">
      <div className="wrapper-container ">
        <Row align="center" className="relative">
          <Col xs={24} md={11}>
            <div className="feature-img">
              <Image src={IMAGE_GRID} alt="feature image" />
            </div>
          </Col>
          <Col xs={24} md={13}>
            <div className="feature-left">
              <div className="feature-tag">{translate('page_home:new_feature')}</div>
              <h2 className="feature-title">
                <Trans i18nKey="page_home:create_your_own_amazing_cv">
                  <span className="high-light" />
                </Trans>
              </h2>
              <div className="feature-summary">{translate('page_home:create_CV_creation_tool')}</div>
              <Button
                id={LP_CANDIDATE_BUTTON_CREATE_CV}
                className="btn-create"
                onClick={() => router.push(currentUser ? LINK_URL_CANDIDATES_CV : LINK_URL_LP_CV_ONLINE)}>
                <span>{translate('page_home:create_my_cv')}</span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default FeatureLPCandidate
