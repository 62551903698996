import { FC, useEffect } from 'react'
import Head from 'next/head'
import { useTranslation } from '../../hooks/useTranslation'
import { removeEndSplash } from '../../utillities/StringUtils'
import useGetAppRealPath from '../../hooks/useGetAppRealPath'
import getTranslatableLinks from '../../utillities/translate/getTranslatableLinks'
export const THUMBNAIL_COMPANY_LP_HH = '/img/company_lp_hh.jpg'
const PublicPageMeta: FC<{
  isAllowNoIndex?: boolean
  title: string
  description: string
  keywords?: string
  url?: string
  image?: string
  noUseHrefLang?: boolean
  alternateLink?: { url: string; lang: string }
}> = ({ isAllowNoIndex, title, description, url, keywords, image, alternateLink }) => {
  const { switchLanguage, currentLanguage } = useTranslation()
  const getAppRealPath = useGetAppRealPath()
  useEffect(() => {
    document.documentElement.lang = currentLanguage
  })
  return (
    <Head>
      <title>{title}</title>
      <meta content={switchLanguage({ vi: 'vi', en: 'en' })} httpEquiv="content-language" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}

      {keywords && <meta itemProp="keywords" content={keywords} />}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image || `${process.env.NEXT_PUBLIC_APP_DOMAIN}${THUMBNAIL_COMPANY_LP_HH}`} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || `${process.env.NEXT_PUBLIC_APP_DOMAIN}${THUMBNAIL_COMPANY_LP_HH}`} />
      <meta
        property="og:url"
        content={removeEndSplash(url) || removeEndSplash(`${process.env.NEXT_PUBLIC_APP_DOMAIN}${getAppRealPath()}`)}
      />
      {isAllowNoIndex ? <meta name="robots" content="noindex, nofollow" /> : <meta property="robots" content="noodp,index,follow" />}
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content={`${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}`} />

      <meta name="twitter:card" content={title} />
      <meta name="twitter:site" content="freec.asia" />
      <meta name="twitter:image" content={image || `${process.env.NEXT_PUBLIC_APP_DOMAIN}${THUMBNAIL_COMPANY_LP_HH}`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {alternateLink ? (
        <link rel="alternate" href={`${alternateLink.url}`} hrefLang={alternateLink.lang} />
      ) : (
        getTranslatableLinks(getAppRealPath()).map((link) => (
          <link
            key={link.lang}
            rel="alternate"
            href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${link.url}`}
            hrefLang={{ vi: 'x-default', en: 'en' }[link.lang]}
          />
        ))
      )}
    </Head>
  )
}
export default PublicPageMeta
