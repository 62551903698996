import { useState, useEffect, useMemo, useCallback } from 'react'
import { BannerType } from '../../types/view/banner/banner'
import { useAsyncAction } from '../share/useAsyncAction'
import { apiCountViewBanner, apiFetchBanner } from '../../service/api/banner.api'

const useBannner = (pageId, size): [Array<BannerType>, boolean] => {
  const [banners, setBanners] = useState<Array<BannerType>>([])

  useEffect(() => {
    fetchBanner()
  }, [])

  const [fetchBanner, loading] = useAsyncAction(() => {
    return apiFetchBanner(pageId, size).then((rs) => {
      setBanners(
        rs.data.data
          .map((b) => ({ id: b.id, ...b.attributes }))
          .sort((a, b) => {
            return a.position - b.position
          })
      )
    })
  })
  return useMemo(() => [banners, loading], [banners])
}
export const useBannnerPositionGroup = (pageId, size): [{ [postId: number]: BannerType[] }, boolean] => {
  const [banners, setBanners] = useState<{ [postId: number]: BannerType[] }>({})
  useEffect(() => {
    fetchBanner()
  }, [])

  const [fetchBanner, loading] = useAsyncAction(() => {
    return apiFetchBanner(pageId, size).then((rs) => {
      setBanners(
        rs.data.data.reduce((res, b) => {
          return { ...res, [b.attributes.position]: [...(res[b.attributes.position] || []), { id: b.id, ...b.attributes }] }
        }, {})
      )
    })
  })
  return useMemo(() => [banners, loading], [banners])
}
export const useBannerTrackingClick = (bannerId) => {
  return () => {
    return apiCountViewBanner(bannerId)
  }
}
export default useBannner
