import { FC } from 'react'
import { listNewFixture } from '../../fixture/news.fixture'
import Image from 'next/image'
import SlickSlider from '../../../../components/SlickSlider'
import ArrowRight from '../../assets/img/arrow_right.svg'
import { Trans } from 'react-i18next'

const NewsLPDesktop: FC = () => {
  const settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    customPaging: function () {
      return <div className="custom-dot"></div>
    },
    dots: true,
    prevArrow: (
      <button className="slick-prev">
        <Image src={ArrowRight} alt="arrow-left" className="img-fluid" />
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <Image src={ArrowRight} alt="arrow-right" className="img-fluid" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div className="news">
      <div className="wrapper-container">
        <h2 className="text-heading">
          <Trans i18nKey="page_home:lp_featured_in">
            <span className="high-light" />
          </Trans>
        </h2>
        <div className="news-wrapper">
          <SlickSlider {...settings}>
            {listNewFixture?.map((item, index) => (
              <a href={item.url} target="blank" className="news-card" key={index}>
                <div className="card-body">
                  <Image width={384} height={256} src={item.image} alt={`image-${index}`} className="card-image" />
                  <div className="card-content">
                    <Image src={item.logo} alt={`logo-${index}`} width={108} height={32} />
                    <div className="description">{item.title}</div>
                  </div>
                </div>
              </a>
            ))}
          </SlickSlider>
        </div>
      </div>
    </div>
  )
}

export default NewsLPDesktop
