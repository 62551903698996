import { match, compile } from 'path-to-regexp'
import config from '../../../../config'
import { getJobQueryFromSEOPathNameV2, toSEOPathNameV2 } from '../SEOUtilities'
import { removeEndSplash } from '../StringUtils'
const LinkResolverHandleMap = {
  JobSearchSlugName: (pathName, lang) => {
    const { search, locationId, categoryId } = getJobQueryFromSEOPathNameV2(pathName)
    return toSEOPathNameV2({ search, locationId, categoryId }, lang)
  }
}
export const getTranslatableLinks = (pathName: string) => {
  const translateRouter = config.routing.translates.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    if (translateRouter.linkResolvers) {
      const rotuerHandle = translateRouter.linkResolvers.find((item) => {
        return item.notMatch && !pathName.match(new RegExp(item.notMatch))
      })
      if (rotuerHandle) {
        return Object.keys(translateRouter).map((lang) => {
          const resolvedHref = LinkResolverHandleMap[rotuerHandle.handler](pathName, lang)
          const matcherSource =
            match<any>(translateRouter.default)(resolvedHref) ||
            (translateRouter.en && match<any>(translateRouter.en)(resolvedHref)) ||
            (translateRouter.vi && match<any>(translateRouter.vi)(resolvedHref))
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          return { lang, url: `${removeEndSplash(compile(translateRouter[lang])(matcherSource.params))}` }
        })
      }
    }

    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    return Object.keys(translateRouter)
      .filter((lang) => lang !== 'default')
      .map((lang) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return { lang, url: `${removeEndSplash(compile(translateRouter[lang])(matcherSource.params))}` }
      })
  }
  return []
}
export default getTranslatableLinks
