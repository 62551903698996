import withLayout from '../../hoc/withLayout'
import withPageCss from '../../hoc/withPageCss'
import initPageLocale from '../../utillities/initPageLocale'
import localeResources from './home.locale'
import LPCandidateWrapper from '../../wrapper/home'
import { EliminateQueryCanonical } from '../../wrapper/jobs/SEOMeta/CanonicalMeta'
import LocalBussinessTags from './LocalBusinessTags'
import PublicPageMeta from '../../components/seo/PublicPageMeta'
import env from '../../env'
import { useUTMCollector, useReferralColector } from '../../modules/utm/UseCollector'
import useRedirectUserLang from '../../hooks/useRedirectUserLang'
import useCheckOnboarding from '../../hooks/useCheckOnboarding'
import { useTranslation } from '../../hooks/useTranslation'
import SiteLinkTags from './SiteLinkTags'

const HomePage = () => {
  useUTMCollector()
  useRedirectUserLang()
  useReferralColector()
  useCheckOnboarding()
  const { translate } = useTranslation()

  return (
    <div>
      <EliminateQueryCanonical isHomePage meaningfulQueryKeyList={[]} />
      <LocalBussinessTags />
      <SiteLinkTags />
      <PublicPageMeta
        title={translate('meta:home_page_title')}
        description={translate('meta:home_page_description')}
        keywords="freec, freec asia, tuyển dụng, tuyển dụng nhanh, website tuyển dụng, tìm việc nhanh, tìm việc uy tín, tạo CV online, công ty đang tuyển dụng"
        image={`${env.PUBLIC_APP_DOMAIN}${'/img/open-graph/share_jobs.jpg'}`}
      />
      <LPCandidateWrapper />
    </div>
  )
}

export default initPageLocale(withLayout(withPageCss(HomePage, { name: 'home', DevCSS: () => import('./home.scss') })), localeResources)
