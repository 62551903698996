import createIconComponent from './createIconComponent'

export const StarFilled = createIconComponent(() => (
  <span role="img" aria-label="star filled icon" className="anticon">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="m12.416 4.827-3.471-.504-1.552-3.146a.44.44 0 0 0-.785 0L5.057 4.323l-3.472.504a.437.437 0 0 0-.242.747l2.512 2.449-.594 3.457a.437.437 0 0 0 .635.46L7 10.31l3.105 1.632a.437.437 0 0 0 .634-.46l-.593-3.458 2.511-2.45a.437.437 0 0 0-.242-.746z"
        fill="#fff"
      />
    </svg>
  </span>
))
export default StarFilled
