import Head from 'next/head'
import { FC } from 'react'
import env from '../../env'
import { useTranslation } from '../../hooks/useTranslation'
const SiteLinkTags: FC = () => {
  const { translate } = useTranslation()
  const siteLinkTags = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `${env.PUBLIC_APP_DOMAIN}`,
    name: translate('meta:home_page_logo'),
    alternateName: translate('meta:home_page_title'),
    description: translate('meta:home_page_description'),
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${env.PUBLIC_APP_DOMAIN}/${translate('meta:find_jobs_url')}/{search_term_string}`
      },
      'query-input': 'required name=search_term_string'
    }
  }
  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `${JSON.stringify(siteLinkTags)}` }} />
    </Head>
  )
}

export default SiteLinkTags
