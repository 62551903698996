import { ClientType } from '@freec/core/lib/utilities/Client'
import { toPagingAPI } from '@freec/core/lib/utilities/URLQuery'
import Client from '../../../utilities/Client'
import {
  API_URL_COUNT_NOTIFICATION,
  API_URL_GET_NOTIFICATION,
  API_URL_READ_ALL_NOTIFICATION,
  API_URL_READ_NOTIFICATION
} from '../APIConstant'

export const saveUserDevicesToken = (device_token: string) => {
  return Client.post('/api/v2/candidates/notifications/registration_notification', { data: { device_token } })
}
export const getNotifications = (client: ClientType) => (params) =>
  client.get(toPagingAPI(API_URL_GET_NOTIFICATION, params, params.page, 20), {})
export const updateReadNotification = (client: ClientType, id) => client.put(API_URL_READ_NOTIFICATION(id), {})
export const updateReadAllNotification = (client: ClientType) => client.put(API_URL_READ_ALL_NOTIFICATION, {})
export const getUnreadNotification = (client: ClientType) => client.get(API_URL_COUNT_NOTIFICATION, {})
