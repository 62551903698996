import Head from 'next/head'
import queryString from 'query-string'
import { FC } from 'react'
import env from '../../../env'
import useAppRouter from '../../../hooks/useAppRouter'
import { toSEOStringFromQuery } from '../../../utillities/SEOUtilities'
import { removeEndSplash } from '../../../utillities/StringUtils'

export const EliminateQueryCanonical: FC<{ isHomePage?: boolean; meaningfulQueryKeyList?: string[]; rootDomain?: string }> = ({
  isHomePage = false,
  meaningfulQueryKeyList,
  rootDomain
}) => {
  const router = useAppRouter()
  const realPath = router.asPath
  const eliminatedQuery = Object.keys(router.query).reduce(
    (query, key) => ({
      ...query,
      ...((meaningfulQueryKeyList || []).includes(key) ? { [key]: toSEOStringFromQuery(router.query[key]) } : {})
    }),
    {}
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const searchString = queryString.stringify(eliminatedQuery, { arrayFormat: 'bracket', decode: false, encode: false })
  const loc = new URL(rootDomain || env.PUBLIC_APP_DOMAIN + realPath)
  const fullUrl = rootDomain || env.PUBLIC_APP_DOMAIN + loc.pathname + (searchString ? `?${searchString}` : '')
  return (
    <Head>
      <link rel="canonical" href={isHomePage ? removeEndSplash(fullUrl).replace('/home', '/') : removeEndSplash(fullUrl)} />
    </Head>
  )
}
