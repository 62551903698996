import en_header from '../../../../src/locales/en-US/header.json'
import vi_header from '../../../../src/locales/vi-VN/header.json'

import en_footer from '../../../../src/locales/en-US/footer.json'
import vi_footer from '../../../../src/locales/vi-VN/footer.json'

import en_meta from '../../../../src/locales/en-US/meta.json'
import vi_meta from '../../../../src/locales/vi-VN/meta.json'

import en_page_home from '../../../../src/locales/en-US/page_home.json'
import vi_page_home from '../../../../src/locales/vi-VN/page_home.json'

import en_page_jobs from '../../../../src/locales/en-US/page_jobs.json'
import vi_page_jobs from '../../../../src/locales/vi-VN/page_jobs.json'

import en_common from '../../../../src/locales/en-US/common.json'
import vi_common from '../../../../src/locales/vi-VN/common.json'

import en_page_home_business_tag from '../../../../src/locales/en-US/page_home_business_tag.json'
import vi_page_home_business_tag from '../../../../src/locales/vi-VN/page_home_business_tag.json'
import en_notification from '../../../../src/locales/en-US/notification.json'
import vi_notification from '../../../../src/locales/vi-VN/notification.json'

import en_enrich_modal from '../../../../src/locales/en-US/enrich_modal.json'
import vi_enrich_modal from '../../../../src/locales/vi-VN/enrich_modal.json'

import en_l4j from '../../../../src/locales/en-US/l4j.json'
import vi_l4j from '../../../../src/locales/vi-VN/l4j.json'

export default {
  en: {
    header: en_header,
    footer: en_footer,
    meta: en_meta,
    page_home: en_page_home,
    page_jobs: en_page_jobs,
    common: en_common,
    page_home_business_tag: en_page_home_business_tag,
    notification: {
      ...en_notification,
      validate_login_fail: 'Login Failed! Please check your email or password again',
      validate_wrong_user_type: 'This email is registered as an employer account. Please sign in from the employer desktop site.',
      company_is_blocked: 'Please contact our support for more details. Email us at support@freec.asia.',
      validate_belongs_to_candidate: 'This email was not registered account on the Candidate platform'
    },
    enrich_modal: en_enrich_modal,
    l4j: en_l4j
  },
  vi: {
    header: vi_header,
    footer: vi_footer,
    meta: vi_meta,
    page_home: vi_page_home,
    page_jobs: vi_page_jobs,
    common: vi_common,
    page_home_business_tag: vi_page_home_business_tag,
    notification: {
      ...vi_notification,
      validate_login_fail: 'Đăng nhập không thành công. Vui lòng kiểm tra lại mail hoặc mật kh',
      validate_wrong_user_type: 'Email này đã được đăng ký với tài khoản công ty. Vui lòng đăng nhập trên giao diện website',
      company_is_blocked:
        'Không thể đăng nhập. Vui lòng liên hệ với bộ phận hỗ trợ của chúng tôi để biết thêm chi tiết. Gửi email theo địa chỉ support@freec.asia.',
      validate_belongs_to_candidate: 'Email này không được đăng ký tài khoản trên nền tảng Ứng viên'
    },
    enrich_modal: vi_enrich_modal,
    l4j: vi_l4j
  }
}
