import { useTranslation } from './useTranslation'

const useConvertSalary = (): { convertSalary: any } => {
  const { translate } = useTranslation()
  return {
    convertSalary: (labelValue: number, isFirst?: boolean): number | string => {
      return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1).replace(/(\.0+|0+)$/, '') + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1).replace(/(\.0+|0+)$/, '') +
          `${!isFirst ? translate('common:covert_salary') : ''}`
        : Math.abs(Number(labelValue))
    }
  }
}
export default useConvertSalary
