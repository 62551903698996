import { FC } from 'react'
import { useTranslation } from '../../../../hooks/useTranslation'
import { Trans } from 'react-i18next'
import SlickSlider from '../../../../components/SlickSlider'
import Image from 'next/image'
import Accounting_Finance from '../../assets/img/Accounting_Finance.webp'
import BuildingConstruction from '../../assets/img/BuildingConstruction.webp'
import DesignMedia from '../../assets/img/DesignMedia.webp'
import EducationTraining from '../../assets/img/EducationTraining.webp'
import Engineering from '../../assets/img/Engineering.webp'
import HR_Recruitment from '../../assets/img/HR_Recruitment.webp'
import IT_NetworkHardware from '../../assets/img/IT-NetworkHardware.webp'
import ITSoftware from '../../assets/img/ITSoftware.webp'
import Management from '../../assets/img/Management.webp'
import Manufacturing from '../../assets/img/Manufacturing.webp'
import Marketing_PR from '../../assets/img/Marketing_PR.webp'
import OperationsCSAdministrative from '../../assets/img/OperationsCSAdministrative.webp'
import Others from '../../assets/img/Others.webp'
import Sales_Business_Development from '../../assets/img/Sales_Business_Development.webp'
import { toCategorySEOPath } from '../../../../utillities/SEOUtilities'

const HotCategory: FC<{ sliderData }> = ({ sliderData }) => {
  const { translate, currentLanguage } = useTranslation()
  const settings = {
    // infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  }

  const HotCatesLogo = {
    Accounting_Finance: Accounting_Finance,
    Building_Construction: BuildingConstruction,
    DesignMedia: DesignMedia,
    EducationTraining: EducationTraining,
    Engineering: Engineering,
    HR_Recruitment: HR_Recruitment,
    IT_Network_Hardware: IT_NetworkHardware,
    ITSoftware: ITSoftware,
    Management: Management,
    Manufacturing: Manufacturing,
    Marketing_PR: Marketing_PR,
    OperationsCS_Administrative: OperationsCSAdministrative,
    Others: Others,
    Sales_Business_Development: Sales_Business_Development
  }

  return sliderData?.length > 0 ? (
    <div className="hot-cates">
      <div className="wrapper-container">
        <h2 className="hot-cates__title">
          <Trans i18nKey="page_home:lp_promissing_industry">
            <span className="high-light" />
          </Trans>
        </h2>
        <div className="hot-cates__slider">
          <SlickSlider {...settings}>
            {sliderData?.map((hotCate) => {
              const hotCateLogoName = hotCate.name
                .replaceAll('/', '')
                .replaceAll('&', '')
                .replaceAll(' ', '_')
                .replaceAll('-', '_')
                .replaceAll('__', '_')
                .trim()

              return (
                HotCatesLogo[hotCateLogoName] && (
                  <a
                    href={toCategorySEOPath({
                      lang: currentLanguage,
                      slug_vi: hotCate.slug_vi,
                      slug_en: hotCate.slug_en,
                      categoryId: hotCate.id
                    })}
                    target="blank"
                    key={hotCate.slug_vi}
                    className="hot-cate">
                    <div className="hot-cate__logo">
                      <Image
                        alt="hot"
                        width={48}
                        height={48}
                        src={HotCatesLogo[hotCateLogoName]?.src}
                        // src={Sales_Business_Development}
                      />
                    </div>
                    <div className="hot-cate__title">{currentLanguage == 'en' ? hotCate.name : hotCate.name_vn}</div>
                    <div className="hot-cate__jobs">
                      {hotCate.total_jobs} {translate('page_home:jobs')}
                    </div>
                  </a>
                )
              )
            })}
          </SlickSlider>
        </div>
      </div>
    </div>
  ) : null
}

export default HotCategory
