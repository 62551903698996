import Script from 'next/script'
import { getClient } from '../../utillities/getIsServer'
import { getCookie } from '../../utillities/Cookies'
import { X_AUTH_TOKEN } from '../../utillities/CONSTANT_COOKIES'

function callback({ data, onError, onSuccess }: { data: any; onError?: any; onSuccess?: any }) {
  if (data?.credential) {
    if (onSuccess) {
      onSuccess(data)
    }
  } else {
    const error = 'Cannot login'
    if (onError) {
      onError(error)
    }
    throw error
  }
}
type IUseGoogleOneTapLogin = {
  onError: () => any
  onSuccess: (rs: any) => Promise<any>
  googleAccountConfigs: any
}
export function GoogleOneTapLogin({ onError, onSuccess, googleAccountConfigs }: IUseGoogleOneTapLogin) {
  // Use the user's custom callback if they specified one; otherwise use the default one defined above:
  const callbackToUse = googleAccountConfigs.callback
    ? googleAccountConfigs.callback
    : (data: any) => callback({ data, onError, onSuccess })

  return (
    <>
      {getClient() && !getCookie(X_AUTH_TOKEN) && (
        <Script
          id="__googleOneTapScript__"
          src="https://accounts.google.com/gsi/client"
          onLoad={() => {
            if (window.google) {
              window.google.accounts?.id?.initialize({
                ...googleAccountConfigs,
                callback: callbackToUse
              })
              window.google.accounts?.id?.prompt()
            }
          }}
        />
      )}
    </>
  )
}
