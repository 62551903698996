import React from 'react'
import Home from '@freec/optimal-pagepages/home/home'
import dynamic from 'next/dynamic'
const NotificationWrapper = dynamic(() => import('../modules/notifications/components/Notification/index'))
const NotificationBell = dynamic(() => import('../modules/notifications/components/NotificationBell/NotificationBell'))
const NotificationModal = dynamic(() => import('../modules/notifications/components/NotificationModal/index'))
const ChatCtxHeader = dynamic(() => import('../modules/chat/components/ChatCtxHeader/ChatCtxHeader'))
const EnrichModal = dynamic(() => import('../modules/enrich/index'))
const HomePage = () => (
  <Home NotificationComponents={{ NotificationWrapper, NotificationBell, NotificationModal, EnrichModal, ChatCtxHeader }} />
)
export default HomePage
export const config = {
  unstable_JsPreload: false
}
