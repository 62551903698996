import { FC } from 'react'
import LinkWrapper from '../../../../../../components/Link'
import useConvertSalary from '../../../../../../hooks/useConvertSalary'
import useIsLogin from '../../../../../../hooks/useIsLogin'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { LINK_URL_LOGIN } from '../../../../../../utillities/LinkURL'

const ViewSalary: FC<{
  min_salary: number
  max_salary: number
  salary_currency: number | string
  negotiable: boolean
  hide_salary: boolean
  fallbackLink?: string
  salary_type?: string
}> = ({ min_salary, max_salary, salary_currency, negotiable, hide_salary, salary_type }) => {
  const { translate, currentLanguage } = useTranslation()
  const { convertSalary } = useConvertSalary()
  const isLogin = useIsLogin()

  if (!isLogin) {
    return (
      <LinkWrapper prefetch={false} href={LINK_URL_LOGIN} as={LINK_URL_LOGIN}>
        <a className="text-bold" onClick={(e) => e.stopPropagation()}>
          {translate('page_jobs:login_view_salary')}
        </a>
      </LinkWrapper>
    )
  }

  if (!salary_type || salary_type === 'negotiable' || hide_salary)
    return <div className="text-salary">{translate('page_companies:negotiable')}</div>
  if (salary_type === 'range')
    return (
      <div className="text-salary">
        {convertSalary(min_salary, true)} - {convertSalary(max_salary)} {salary_currency}
      </div>
    )
  if (salary_type === 'upto')
    return (
      <div className="text-salary">
        {currentLanguage === 'vi'
          ? `Đến ${convertSalary(max_salary || 0)} ${salary_currency}`
          : `Up to ${convertSalary(max_salary || 0)} ${salary_currency}`}
      </div>
    )
  if (salary_type === 'from_min')
    return (
      <div className="text-salary">
        {currentLanguage === 'vi'
          ? `Từ ${convertSalary(min_salary || 0)} ${salary_currency}`
          : `From ${convertSalary(min_salary || 0)} ${salary_currency}`}
      </div>
    )
  return <div />

  // return (
  //   <div>
  //     {!isLogin ? (
  //       <LinkWrapper prefetch={false} href={LINK_URL_LOGIN} as={LINK_URL_LOGIN}>
  //         <a className="text-bold" onClick={(e) => e.stopPropagation()}>
  //           {translate('page_jobs:login_view_salary')}
  //         </a>
  //       </LinkWrapper>
  //     ) : negotiable ? (
  //       <>{translate('page_companies:negotiable')}</>
  //     ) : (
  //       <>
  //         {convertSalary(min_salary, true)} - {convertSalary(max_salary)} {salary_currency}
  //       </>
  //     )}
  //   </div>
  // )
}

export default ViewSalary
