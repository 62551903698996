const Col = ({ children = null, className = null, xs = 0, sm = 0, md = 0, lg = 0, xl = 0, xxl = 0, ...props }) => (
  <div
    {...props}
    className={`${className || ''} col${xs ? ` col-xs-${xs}` : ''}${sm ? ` col-sm-${sm}` : ''}${md ? ` col-md-${md}` : ''}${
      lg ? ` col-lg-${lg}` : ''
    }${xl ? ` col-xl-${xl}` : ''}${xxl ? ` col-xxl-${xxl}` : ''}`}>
    {children}
  </div>
)
export default Col
