import { useEffect, useState } from 'react'

export enum FORM_PRODUCT {
  NATIVE_APP = 0,
  WEB_MOBILE = 1,
  WEB_DESKTOP = 2
}
const MOBILE_MEDIA_BREAK_POINT = 768
const useFromProduct = () => {
  const [isMobile, setMobile] = useState<boolean>(false)
  useEffect(() => {
    const handleResizeComponent = () => {
      if (isMobile) {
        if (window.innerWidth > MOBILE_MEDIA_BREAK_POINT) {
          setMobile(false)
        }
      } else {
        if (window.innerWidth <= MOBILE_MEDIA_BREAK_POINT) {
          setMobile(true)
        }
      }
    }
    window.addEventListener('resize', handleResizeComponent)
    return () => {
      window.removeEventListener('resize', handleResizeComponent)
    }
  }, [isMobile])
  return isMobile ? FORM_PRODUCT.WEB_MOBILE : FORM_PRODUCT.WEB_DESKTOP
}
export default useFromProduct
